import React, { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import {
  Hits,
  SortBy,
  useStats,
  SearchBox,
  Configure,
  InstantSearch,
  useClearRefinements,
  Pagination
} from 'react-instantsearch';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import IconCollection from '../IconCollection/IconCollection';
import AlogoliaSearchCard from '../AlogoliaSearchCard/AlogoliaSearchCard';
import AlgoliaFilterPanel from '../../containers/AlgoliaSearchPage/AlgoliaFilterPanel';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import { makeAlgoliaSearchParams, setAlgoliaInitailState } from '../../util/data';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import css from './AlgoliaSearchContent.module.css';

const {
  REACT_APP_ALGOLIA_APP_ID,
  REACT_APP_ALGOLIA_SEARCH_API_KEY
} = process.env;


const searchClient = algoliasearch(
  REACT_APP_ALGOLIA_APP_ID,
  REACT_APP_ALGOLIA_SEARCH_API_KEY,
  {
    _useRequestCache: true,
  }
);

const Panel = ({ children, header, footer }) => {
  return (
    <div className="ais-Panel">
      {header && <div className="ais-Panel-header">{header}</div>}
      <div className="ais-Panel-body">{children}</div>
      {footer && <div className="ais-Panel-footer">{footer}</div>}
    </div>
  );
};

const CustomStats = props => {
  const { nbHits, page, hitsPerPage } = useStats();
  return (
    <div className={css.noDataFoundWrapper}>
      <span className={css.searchResults}>
        {/* Showing {page + 1} - {hitsPerPage} out of  */}
        {nbHits} results
      </span>
      <div
        className={classNames(
          css.noDataFound
        )}
      >
        {' '}
        {nbHits < 1 ? <FormattedMessage id="AlgoliaSearchPage.noResults" /> : null}
      </div>
    </div>
  );
};


const customConfigureProps = {
  filters: `state:published`,
  ruleContexts: [],
  hitsPerPage: 12
}

const AlgoliaSearchContent = (props) => {
  const {
    indexName,
    currentUser,
    currentPage,
    isMobileLayout = true,
    configureProps = customConfigureProps,
    SortByFilters,
    allfilters,
    categoryPageName,
  } = props;

  const history = useHistory();
  const routes = useRouteConfiguration();

  const isWindowDefined = typeof window !== 'undefined';
  if (!isWindowDefined || !currentPage || !indexName) return null;

  const searchQuery = parse(window?.location?.search) || "";
  const params = useParams();

  const [isMobileFilter, setIsMobileFilter] = useState(false);
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  // state for algolia 
  const [algoliaState, setAlgoliaState] = useState(searchQuery);
  const isFilterApplied = typeof algoliaState == "object" && Object.keys(algoliaState).length;

  const Hit = props => {
    const { hit } = props;
    return (
      <AlogoliaSearchCard
        hit={hit}
        currentUser={currentUser}
        algoliaState={algoliaState}
        currentPage={currentPage}
        categoryPageName={categoryPageName}
      />
    );
  };

  const sortBy = Array.isArray(SortByFilters) && SortByFilters.length ? (
    <div className={css.shortWrapper}>
      <SortBy
        items={SortByFilters}
      />
    </div>
  ) : null;

  const ClearFilters = () => {
    const { refine, canRefine } = useClearRefinements();
    return (
      <div className={css.clearFilter}>
        <button
          className={`ais-ClearRefinements-button ${!canRefine ? 'ais-ClearRefinements-button--disabled' : ''
            }`}
          disabled={!canRefine}
          type="button"
          onClick={(e) => {
            refine(e);
            setAlgoliaState({});
            history.push(createResourceLocatorString(currentPage, routes, { ...params }, {}));
            return window.location.reload();
          }}
        >
          <div className="clear-filters">
            {/* <IconProfileCard type="clear" /> */}
            Clear filters
          </div>
        </button>

      </div>
    );
  }

  const filterPanel = (Array.isArray(allfilters) && allfilters.length) ? <AlgoliaFilterPanel
    Panel={Panel}
    currentUser={currentUser}
    algoliaState={algoliaState}
    isMobileLayout={isMobileLayout}
    showMoreFilters={showMoreFilters}
    setShowMoreFilters={setShowMoreFilters}
    filterPanel={allfilters}
    currentPage={currentPage}
    categoryPageName={categoryPageName}
  /> : null;

  const searchFilters = <div className={css.searchfilter}>

    <div className={css.rightFilters}>
      <div
        className={classNames(
          css.aisInstantSearch,
          currentUser ? '' : css.notLoginUserSearch
        )}
      >
        <div className={classNames(css.filterWrapper, css.filterDesktop)}>
          {filterPanel}
        </div>
      </div>

      {isMobileLayout || !(Array.isArray(allfilters) && allfilters.length && allfilters.filter((st) => st.moreFilter).length) ? null :
        <div className={css.clearFilter}>
          <button
            className={`ais-ClearRefinements-button`}
            type="button"
            onClick={(e) => {
              setShowMoreFilters(true)
            }}
          >
            <div className="clear-filters">
              + More Filters
            </div>
          </button>
        </div>
      }
      {isMobileLayout ? null : <div>{sortBy}</div>}
      {isFilterApplied || ["ProfilePage"].includes(currentPage) ? <div><ClearFilters /></div> : null}
    </div>
    <div className={css.profilesearchBox}>
      <SearchBox
        autoFocus
        placeholder="Search by keyword or store name"
        searchAsYouType={true}
      />
    </div>
  </div>;

  const makeSearchRoute = (uiState) => {
    const searchParams = makeAlgoliaSearchParams(indexName, uiState);
    setAlgoliaState(searchParams);
    return currentPage=="CMSPage" ? null : history.push(createResourceLocatorString(currentPage, routes, { ...params }, searchParams));
  };

  const onStateChange = (props) => {
    const { uiState, setUiState } = props;
    setUiState(uiState);
    makeSearchRoute(uiState);
  };

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
      insights={true}
      onStateChange={onStateChange}
      initialUiState={setAlgoliaInitailState(indexName, searchQuery, configureProps)}
    >
      <Configure
        {...configureProps}
      />
      {currentPage == "CMSPage" ? <>
        {filterPanel}
        <Hits hitComponent={Hit} />
        <button className={css.seeAllLisitng}
          onClick={(e) => {
            e.preventDefault();
            const { searchKey, value } = categoryPageName;
            const searchParams = searchKey && value ? {
              [searchKey]: value
            } : {};
            return history.push(createResourceLocatorString('SearchPage', routes, {}, {...searchParams, ...algoliaState}));
          }} >
          <FormattedMessage id="SectionContainer.seeAllListings" />
        </button>
      </>
        : <>
          <div>
            {isMobileLayout ?
              <div className={css.mobileFilters}>
                <Button onClick={(e) => {
                  e.preventDefault();
                  setIsMobileFilter(true);
                }} className={css.mobileButton}>Filters</Button>
                <div className={css.mobileButton}>
                  {sortBy}
                </div>
              </div>
              : searchFilters
            }

            <Modal
              isOpen={isMobileFilter}
              onClose={() => setIsMobileFilter(false)}
              onManageDisableScrolling={() => { }}
              modalTitle="Filters"
              id="AlgoliasSearchPage.filterModal"
              className={css.filterModal}
            >
              <div className={classNames(css.filterWrapper)}>  {searchFilters}</div>
            </Modal>

            <div
              className={classNames(
                css.Resultcontainer
              )}
            >
              <section className={css.conatinerResult}>
                <header className="container-header container-options header-desktop">
                  <div className={css.filterResult}>
                    <CustomStats />
                  </div>
                </header>
                <header className="container-header container-options header-mobile">

                  <div onClick={() => setIsMobileFilter(!isMobileFilter)}>
                    <IconCollection icon="filterIcon" />
                  </div>
                </header>
              </section>
              <div>
                <Hits hitComponent={Hit} />
                {/* <footer className="container-footer"> */}

                {/* </footer> */}
              </div>
            </div>
          </div>
          <div className={css.paginationLink}>
            <Pagination />
          </div>
        </>}
    </InstantSearch>
  )
}

export default AlgoliaSearchContent
