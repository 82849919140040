import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Menu, RefinementList, ToggleRefinement, useMenu, useRange, useRefinementList, useToggleRefinement } from 'react-instantsearch';
import { Button, Modal, OutsideClickHandler } from '../../components';
import { CLICKED_FILTERS, CONVERTED_FILTERS, FILTER_CLICKED, FILTER_CONVERSATION, algoliaEventTrackFn } from './AlgoliaEvents';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { ALOGOLIA_SEARCH_ATTRIBUTES, ALOGOLIA_SEARCH_COMPONENT, SEARCH_KEY_PARAMS } from '../../util/types';
import { onGetUserDetails } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';

import css from './AlgoliaSearchPage.module.css';

const { refinement_list, range_input, menu, menuSelect, selectButton, customRefinementList } = ALOGOLIA_SEARCH_COMPONENT;

function CustomToggleRefinement(props) {
  const { attribute, currentUser, algoliaState, label, name, setAlgoliaState  } = props;
  const { value: item, refine } = useToggleRefinement({ attribute });
  const { count = 0 } = typeof item.onFacetValue == "object" ? item.onFacetValue : {};
  const searchkey = SEARCH_KEY_PARAMS[attribute];
  const isConverted = typeof algoliaState == "object" && algoliaState[searchkey] ? algoliaState[searchkey] : false;

  return (
    <li key={attribute}>
      <label>
        <input
          type="checkbox"
          checked={isConverted || item.isRefined}
          onChange={(event) => {
            const isRefined = !event.target.checked;
            setAlgoliaState({...algoliaState , [name]:!isConverted })
            setTimeout(()=>{
              refine({ isRefined});
              const meuuAttribute = attribute;
              const currentUserId = currentUser?.id?.uuid;
              if (currentUserId) {
                const index = process.env.REACT_APP_ALGOLIA_LISTING_INDEX;
                const { name } = onGetUserDetails(currentUser);
  
                const eventName = isConverted ? FILTER_CONVERSATION : FILTER_CLICKED;
                const method = isConverted ? CONVERTED_FILTERS : CLICKED_FILTERS
  
                algoliaEventTrackFn(method, {
                  index,
                  eventName,
                  userToken: currentUserId,  // required for Node.js
                  filters: [encodeURI(`${meuuAttribute}:${item.value}`)],
                  eventData: {
                    UserID: currentUserId,
                    userName: name
                  }
                })
              };

            },200);
          }
          }
        />
        <div className={css.selectCategory}>
          <span>{label}</span>
          <span className={css.count}> ({count || 0})</span>
        </div>
      </label>
    </li>
  );
}

const CustomRefinementList = (props) => {
  const { label, attribute, currentUser, algoliaState,setAlgoliaState, ...rest } = props;
  const {
    items,
    refine,
  } = useRefinementList({ attribute });

  const [isOpen, setIsOpen] = useState(false);
  const toggleBox = () => {
    setIsOpen(!isOpen);
  };

  const searchkey = SEARCH_KEY_PARAMS[attribute];
  const isConverted = typeof algoliaState == "object" && algoliaState[searchkey] ? algoliaState[searchkey] : "";

  const isCollection =  Array.isArray(items) && items.length && attribute == ALOGOLIA_SEARCH_ATTRIBUTES["collection"];

  const filterPill = Array.isArray(items) &&  typeof algoliaState == "object" && algoliaState["businessCollection"] && algoliaState["theEdit"] 
                     ? 2 + items.filter((st) => st.isRefined).length 
                     :  Array.isArray(items) &&  typeof algoliaState == "object" && (algoliaState["businessCollection"] || algoliaState["theEdit"]) 
                     ? 1 + items.filter((st) => st.isRefined).length  
                     : Array.isArray(items) && items.filter((st) => st.isRefined).length 
                     
  return (
    <div className={css.priceInput}>
      <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
        <button className={css.priceButton} onClick={toggleBox}>
          {label}
          {isCollection && filterPill ? ` (${filterPill})` : items.filter((st) => st.isRefined).length  ? ` (${items.filter((st) => st.isRefined).length})` : ""}
        </button>
        {isOpen
          ? <ul className={css.priceDropDown}>
            {items.sort((a, b) => a.value.localeCompare(b.value)).filter((st) => st.value != "Custom").map((item) => (
              <li key={item.label}>
                <label>
                  <input
                    type="checkbox"
                    checked={item.isRefined}
                    onChange={() => {
                      refine(item.value);
                      const meuuAttribute = attribute;

                      const currentUserId = currentUser?.id?.uuid;

                      if (currentUserId) {
                        const index = process.env.REACT_APP_ALGOLIA_LISTING_INDEX;
                        const { name } = onGetUserDetails(currentUser);

                        const eventName = isConverted ? FILTER_CONVERSATION : FILTER_CLICKED;
                        const method = isConverted ? CONVERTED_FILTERS : CLICKED_FILTERS

                        algoliaEventTrackFn(method, {
                          index,
                          eventName,
                          userToken: currentUserId,  // required for Node.js
                          filters: [encodeURI(`${meuuAttribute}:${item.value}`)],
                          eventData: {
                            UserID: currentUserId,
                            userName: name
                          }
                        })
                      };
                    }
                    }
                  />
                  <div className={css.selectCategory}>
                    <span>{item.label}</span>
                    <span className={css.count}> ({item.count})</span>
                  </div>
                </label>
              </li>
            ))}

            {isCollection
              ? <>
                <CustomToggleRefinement
                  attribute="publicData.businessCollection"
                  label="Pakihi Māori"
                  currentUser={currentUser}
                  algoliaState={algoliaState}
                  setAlgoliaState={setAlgoliaState}
                  name="businessCollection"
                />
                <CustomToggleRefinement
                  attribute="publicData.theEdit"
                  label="The Edit"
                  currentUser={currentUser}
                  algoliaState={algoliaState}
                  setAlgoliaState={setAlgoliaState}
                  name="theEdit"
                />
              </>
              : null}
          </ul>
          : null}
      </OutsideClickHandler>
    </div>
  );
}

const SelectFilter = props => {
  const { label, attribute } = props;
  const { currentUser, algoliaState, ...rest } = props;
  const { items, refine } = useMenu({ ...rest });
  const { value: selectedValue } = items.find((item) => item.isRefined) || {
    value: '',
  };

  const searchkey = SEARCH_KEY_PARAMS[attribute];
  const isConverted = typeof algoliaState == "object" && algoliaState[searchkey] ? algoliaState[searchkey] : "";

  return (
    <select
      value={selectedValue || isConverted}
      onChange={(event) => {
        refine(event.target.value);
        const meuuAttribute = attribute;

        const currentUserId = currentUser?.id?.uuid;
        if (currentUserId) {
          const index = process.env.REACT_APP_ALGOLIA_LISTING_INDEX;
          const { name } = onGetUserDetails(currentUser);

          const eventName = isConverted ? FILTER_CONVERSATION : FILTER_CLICKED;
          const method = isConverted ? CONVERTED_FILTERS : CLICKED_FILTERS

          algoliaEventTrackFn(method, {
            index,
            eventName,
            userToken: currentUserId,  // required for Node.js
            filters: [encodeURI(`${meuuAttribute}:${event.target.value}`)],
            eventData: {
              UserID: currentUserId,
              userName: name
            }
          })
        };
      }}
    >
      <option selected value={""}> {label} </option>
      {items.map((item) => (
        <option value={item.value}>
          {item.label} ({item.count})
        </option>
      ))}
    </select>
  );
};

const SelectButton = props => {
  const history = useHistory();
  const routes = useRouteConfiguration();
  const { label, attribute } = props;
  const { categoryPageName, currentUser, algoliaState, ...rest } = props;
  const { items, refine } = useMenu({ ...rest });
  const { value: selectedValue } = items.find((item) => item.isRefined) || {
    value: '',
  };

  const { option, publicDataKey, value, searchKey } = (typeof categoryPageName == "object" && categoryPageName) || {};

  // selectedValue == item.value ? true : false
  return (
    <div className={css.filter}>
      <div className={css.filterBox}>
        <Button className={css.activesubCat}>Recently Posted</Button>
        {!["Pakihi Māori", "The Edit"].includes(option) && Array.isArray(items) && items.sort((a, b) => a.label.localeCompare(b.label)).map((item) => (
          <Button className={selectedValue == item.value ? css.activesubCat : css.inActivesubCat} key={item.label} onClick={() => {
            refine(item.value)
          }}>
            {item.label}
            {/* ({item.count}) */}
          </Button>
        ))}
      </div>
      <div className={css.seeMoreButton}
        onClick={(e) => {
          e.preventDefault();
          const searchParams = searchKey && value ? {
            [searchKey]: value,
            ...(selectedValue ? { [SEARCH_KEY_PARAMS["publicData.subCategory"]]: selectedValue } : {})
          } : {};

          return history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
        }}>
        see more <span>{option || ""}</span> Products
      </div>
    </div>
  );
};

// function PrinceRangeInput(props) {
//   const [isOpen, setIsOpen] = useState(false);
//   const {attribute} = props;
//   return (
//     <div className={css.priceInput}>
//       <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
//         <button onClick={() => setIsOpen(true)}>
//           Price
//         </button>
//         {isOpen ? <RangeInput attribute={attribute} /> : null}
//       </OutsideClickHandler>
//     </div>
//   );
// }

function stripLeadingZeroFromInput(value = '') {
  return value.toString().replace(/^(0+)\d/, part => Number(part).toString());
}

function PrinceRangeInput(props) {
  const [isOpen, setIsOpen] = useState(false);

  const { start, range, canRefine, precision, refine } = useRange(props);
  const step = 1 / Math.pow(10, precision || 0);

  const values = {
    min:
      start[0] !== -Infinity && start[0] !== range.min
        ? start[0]
        : "",
    max:
      start[1] !== Infinity && start[1] !== range.max
        ? start[1]
        : "",
  };

  const [prevValues, setPrevValues] = useState(values);

  const [{ from, to }, setRange] = useState({
    from: values.min?.toString(),
    to: values.max?.toString(),
  });

  if (values.min !== prevValues.min || values.max !== prevValues.max) {
    setRange({ from: values.min?.toString(), to: values.max?.toString() });
    setPrevValues(values);
  }

  const minMax = { min: range.min, max: range.max };

  const toggleBox = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={css.priceInput}>
      <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
        <button className={css.priceButton} onClick={toggleBox}>
          Price
        </button>
        {isOpen && (
          <form
            className={css.priceForm}
            onSubmit={event => {
              event.preventDefault();
              const data = [
                from ? Number(from) : undefined,
                to ? Number(to) : undefined,
              ];
              refine(data);
            }}
          >
            {/* <div>Price Range:</div> */}
            <div className={css.filterNumber}>
              <label>
                <input
                  type="number"
                  {...minMax}
                  value={stripLeadingZeroFromInput(
                    from || ""
                  )}
                  step={step}
                  placeholder={range.min?.toString()}
                  disabled={!canRefine}
                  onInput={({ currentTarget }) => {
                    const value = currentTarget.value;
                    // const valueInCents = Number(value || 0) * 100;
                    setRange({ from: value || "", to });
                  }}
                />
              </label>
              <span> to </span>
              <label>
                <input
                  type="number"
                  {...minMax}
                  value={stripLeadingZeroFromInput(to || "")}
                  step={step}
                  placeholder={range.max?.toString()}
                  disabled={!canRefine}
                  onInput={({ currentTarget }) => {
                    const value = currentTarget.value;
                    setRange({ from, to: value || "" });
                  }}
                />
              </label>
            </div>
            <button className={css.submitButton} type="submit">
              Go
            </button>
          </form>
        )}
      </OutsideClickHandler>
    </div>
  );
}



const AlgoliaFilterPanel = ({ Panel, isMobileLayout = true, showMoreFilters, setShowMoreFilters, currentUser, algoliaState, filterPanel = [], currentPage, categoryPageName , setAlgoliaState }) => {

  const intl = useIntl();

  // intl.formatMessage({ id: 'Avatar.bannedUserDisplayName' })


  const getFilterComponent = (item) => {
    const { component } = item;
    return component == range_input ? <PrinceRangeInput attribute={'price'} />
      : component == refinement_list ?
        <RefinementList
          attribute={item.attribute}
          searchablePlaceholder={item.searchablePlaceholder}
          showMore={item.showMore}
        />
        : component == menu ?
          <Menu
            attribute={item.attribute}
            showMore={item.showMore}
          />
          : component == menuSelect ?
            <SelectFilter
              key={item.attribute}
              attribute={item.attribute}
              label={item.title}
              currentUser={currentUser}
              algoliaState={algoliaState}
            />
            : component == customRefinementList ?
              <CustomRefinementList
                key={item.attribute}
                attribute={item.attribute}
                label={item.title}
                currentUser={currentUser}
                algoliaState={algoliaState}
                setAlgoliaState={setAlgoliaState}
              />
              : component == selectButton ?
                <SelectButton
                  key={item.attribute}
                  attribute={item.attribute}
                  label={item.title}
                  currentUser={currentUser}
                  algoliaState={algoliaState}
                  categoryPageName={categoryPageName}
                />
                : null
  };

  return (
    <>
      {filterPanel.filter((st) => isMobileLayout || !(st.moreFilter)).map((item, index) => (<Panel key={item.title}>{getFilterComponent(item)}</Panel>))}
      <Modal
        isOpen={showMoreFilters}
        onClose={() => setShowMoreFilters(false)}
        onManageDisableScrolling={() => { }}
        modalTitle="Filters"
        id="AlgoliaFilterPanel.filterModal"
        className={css.moreFilterModal}
      >
        <div>
          <h4>More Filters</h4>
          <div className={css.moreFilterModalContent}>
            {filterPanel.filter((st) => st.moreFilter).map((item, index) => (<Panel key={item.title}>{getFilterComponent(item)}</Panel>))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AlgoliaFilterPanel;
